import React from 'react';
import { TbBrandMysql } from "react-icons/tb";


const Technical = () => {
    return (
        <div className="bg-gradient-to-r from-blue-900 to-black text-white py-12 px-6">
            {/* Technical Skills Header */}
            <div className="text-center mb-16">
                <h1 className="text-4xl md:text-5xl font-bold text-blue-400 animate-pulse text-center mb-8">Technical Skills</h1>
                <div className="w-24 h-1 bg-purple-600 mx-auto"></div>
            </div>

            <div className="max-w-6xl mx-auto grid grid-cols-1 lg:grid-cols-2 gap-12">
                {/* Frontend Development */}
                <div>
                    <h2 className="text-2xl font-semibold mb-6">Frontend Development</h2>
                    <div className="space-y-6">
                        {[
                            { name: "React.js", rating: 9 },
                            { name: "JavaScript", rating: 8 },
                            { name: "CSS/Tailwind", rating: 7 },
                        ].map((skill) => (
                            <div key={skill.name} className="space-y-2">
                                <div className="flex justify-between">
                                    <span>{skill.name}</span>
                                    <span>{skill.rating}/10</span>
                                </div>
                                <div className="h-2 bg-gray-700 rounded-full">
                                    <div
                                        className="h-full bg-purple-600 rounded-full"
                                        style={{ width: `${skill.rating * 10}%` }}
                                    ></div>
                                </div>
                            </div>
                        ))}
                    </div>

                </div>

                {/* Technologies & Tools */}
                <div>
                    <h2 className="text-2xl font-semibold mb-6">Technologies & Tools</h2>
                    <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
                        {[
                            { name: "React", icon: "⚛️" },
                            { name: "Firebase", icon: "🔥" },
                            { name: "AWS / VPS", icon: "☁️" },
                            { name: "MySQL", icon: <TbBrandMysql style={{height: 35, width: 35}}/> },
                            { name: "MongoDB", icon: "🗄️" },
                            { name: "Elasticsearch", icon: "🔍" }
                        ].map((tech) => (
                            <div
                                key={tech.name}
                                className="bg-gray-800 p-4 rounded-lg flex flex-col items-center justify-center space-y-2 hover:bg-gray-700 transition-colors"
                            >
                                <span className="text-2xl">{tech.icon}</span>
                                <span className="text-sm">{tech.name}</span>
                            </div>
                        ))}
                    </div>
                </div>

                {/* Backend Development */}
                <div>
                    <h2 className="text-2xl font-semibold mb-6">Backend Development</h2>
                    <div className="space-y-6">
                        {[
                            { name: "Node.js", rating: 8.8 },
                            { name: "Python", rating: 8.2 },
                        ].map((skill) => (
                            <div key={skill.name} className="space-y-2">
                                <div className="flex justify-between">
                                    <span>{skill.name}</span>
                                    <span>{skill.rating.toFixed(1)}/10</span> {/* Showing rating with one decimal place */}
                                </div>
                                <div className="h-2 bg-gray-700 rounded-full">
                                    <div
                                        className="h-full bg-purple-600 rounded-full"
                                        style={{ width: `${skill.rating * 10}%` }}
                                    ></div>
                                </div>
                            </div>
                        ))}
                    </div>

                </div>

                {/* Soft Skills */}
                <div>
                    <h2 className="text-2xl font-semibold mb-6">Soft Skills</h2>
                    <div className="flex flex-wrap gap-3">
                        <span className="px-4 py-2 bg-purple-900 rounded-full text-sm">Team Leadership</span>
                        <span className="px-4 py-2 bg-purple-800 rounded-full text-sm">Problem Solving</span>
                        <span className="px-4 py-2 bg-green-900 rounded-full text-sm">Communication</span>
                        <span className="px-4 py-2 bg-red-900 rounded-full text-sm">Project Management</span>
                        <span className="px-4 py-2 bg-yellow-900 rounded-full text-sm">Agile/Scrum</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Technical