import React, { useState, useEffect } from "react";
import { PersonalInfoapi, PersonalInfoget, addbackend, deletebackend } from "../../../../services/api";
import { useSelector } from "react-redux";
import { IoTrashBin } from "react-icons/io5";
import { MdAddCircle } from "react-icons/md";
import { FaEdit } from "react-icons/fa";
import { TiCancel } from "react-icons/ti";
import { FaStar } from "react-icons/fa";


const Backend = () => {
  const userId = useSelector((state) => state.auth.user?.currentUser?._id) || null;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [backends, setBackend] = useState([]);
  const [showEditBackModal, setShowEditBackModal] = useState(false);
  const [selectedBack, setSelectedBack] = useState(null);
  const [showAddModal, setShowAddModal] = useState(false);

  const [formData, setFormData] = useState({
    b_name: "",
    rating: ""
  });

  useEffect(() => {
    if (!userId) return;
    const fetchExpData = async () => {
      setLoading(true);
      try {
        const response = await PersonalInfoget(userId);
        setBackend(response.data.user.backend);
      } catch (err) {
        setError("Failed to fetch user data.");
      } finally {
        setLoading(false);
      }
    };
    fetchExpData();
  }, [userId]);

  useEffect(() => {
    if (selectedBack) {
      setFormData(selectedBack);
    }
  }, [selectedBack]);

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleUpdateBackend = async () => {
    try {
      const updatedData = {
        backend: backends.map((back) =>
          back._id === selectedBack._id ? { ...back, ...formData } : back
        ),
      };

      await PersonalInfoapi(userId, updatedData);
      setBackend(updatedData.backend);
      setShowEditBackModal(false);
      setSelectedBack(null);
    } catch (error) {
      console.error("Error updating backend:", error);
    }
  };

  const handleAddBackend = async () => {
    if (!userId) {
      console.error("User ID not found");
      return;
    }
    try {
      setLoading(true);
      console.log("formData: ", formData);
      const response = await addbackend(userId, formData);
      setBackend(response.data.backend);
      setShowAddModal(false);
      setFormData({
        b_name: "",
        rating: ""
      });
    } catch (error) {
      console.error("Error adding backend:", error);
    } finally {
      setLoading(false);
    }
  };

  const handledeleteinforow = async (id) => {
    if (!userId) {
      console.error("User ID not found");
      return;
    }
    try {
      await deletebackend(userId, id);
      setBackend((prevBackends) => prevBackends.filter((back) => back._id !== id));
      setShowEditBackModal(false);

    } catch (error) {
      console.error("Error deleting backend:", error);
    }
  };



  return (
    <div>
      <div className="p-4 flex items-center justify-between text-white border-2 border-gray-200 border-dashed rounded-lg dark:border-gray-700">
        <h1 className="text-2xl xl:text-3xl font-extrabold">Backend</h1>
        <button
          type="button"
          className="flex items-center justify-center gap-2 text-white bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5"
          onClick={() => setShowAddModal(true)}
        >
          <svg className="w-6 h-6 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 12h14m-7 7V5" />
          </svg>
          Add Backend
        </button>
      </div>

      <div className="p-4 mt-3 border-2 border-gray-200 border-dashed rounded-lg dark:border-gray-700">
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
          <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th className="px-6 py-3">Backend Name</th>
                <th className="px-6 py-3">Rating</th>
              </tr>
            </thead>
            <tbody>
              {backends.map((backend) => (
                <tr key={backend._id} onClick={() => { setSelectedBack(backend); setShowEditBackModal(true); }} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                  <td className="px-6 py-3">{backend.b_name}</td>
                  <td className="px-6 py-3 flex items-center">
                    {Array.from({ length: backend.rating }, (_, index) => (
                      <FaStar key={index} className="text-yellow-500" />
                    ))}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {showEditBackModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-5 rounded-lg w-1/3">
            <h2 className="text-xl font-bold mb-4">Edit Backend</h2>
            <input type="text" name="b_name" value={formData.b_name} placeholder="Backend Name" className="block w-full p-2 border rounded mb-2" onChange={handleInputChange} />
            <input type="text" name="rating" value={formData.rating} placeholder="rating" className="block w-full p-2 border rounded mb-2" onChange={handleInputChange} />

            <div className="flex justify-between gap-2 mt-4">
              <button className="bg-gray-500 text-white px-4 py-2 rounded flex gap-2 items-center justify-center" onClick={() => { setShowEditBackModal(false); setSelectedBack(null); }}><TiCancel /> Cancel</button>
              <button className="bg-green-500 text-white px-4 py-2 rounded flex gap-2 items-center justify-center" onClick={handleUpdateBackend}><FaEdit /> Update</button>
              <button className="bg-red-500 flex gap-2 items-center justify-center text-white px-4 py-2 rounded" onClick={() => { handledeleteinforow(formData._id); }}> <IoTrashBin /> Delete</button>
            </div>
          </div>
        </div>
      )}


      {(showAddModal) && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-5 rounded-lg w-1/3">
            <h2 className="text-xl font-bold mb-4">{showEditBackModal ? "Edit Backend" : "Add Backend"}</h2>
            <input type="text" name="b_name" placeholder="Backend Name" className="block w-full p-2 border rounded mb-2" onChange={handleInputChange} />
            <input type="text" name="rating" placeholder="Rating" className="block w-full p-2 border rounded mb-2" onChange={handleInputChange} />
            <div className="flex justify-between gap-2 mt-4">
              <button className="bg-red-500 text-white px-4 py-2 rounded flex gap-2 items-center justify-center" onClick={() => { setShowEditBackModal(false); setShowAddModal(false); }}> <TiCancel />Close</button>
              <button className="bg-green-500 text-white px-4 py-2 rounded flex gap-2 items-center justify-center" onClick={handleAddBackend}> <MdAddCircle /> Add Backend</button>
            </div>
          </div>
        </div>
      )}

    </div>
  );
};

export default Backend;
