import React, { useState, useEffect } from 'react';  // Import useEffect
import { editblog } from "../../../services/api";  // Import the editproject API function

const EditBlogModal = ({ blog, fetchallblogsData, onClose }) => {
    const [formData, setFormData] = useState({
        title: '',
        sub_title: '',
        description: '',
        date: '',
        auther_name: '',
        image_link: '',
        color_code: '',
    });

    useEffect(() => {
        if (blog && blog._id) {
            // Initialize formData only if the blog is available
            setFormData({
                title: blog.title,
                sub_title: blog.sub_title,
                description: blog.description,
                date: blog.date,
                auther_name: blog.auther_name,
                image_link: blog.image_link,
                color_code: blog.color_code,
            });
            console.log("blog: ", blog._id);
        }
    }, [blog]);  // Re-run this effect when the project prop changes

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await editblog(blog._id, formData);  // Make the API call to update the blog
            console.log('Updated blog:', formData);
            await fetchallblogsData();  // Now this will correctly call the function passed as a prop
            onClose();  // Close the modal after submission
        } catch (error) {
            console.error('Error updating blog:', error);
        }
    };

    return (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-50 flex justify-center items-center z-50">
            <div className="bg-white rounded-lg w-96 p-6">
                <h3 className="text-2xl font-semibold mb-4">Edit blog</h3>
                <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label htmlFor="title" className="block text-sm font-medium text-gray-700">Title</label>
                        <input
                            type="text"
                            name="title"
                            value={formData.title}
                            onChange={handleInputChange}
                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>

                    <div className="mb-4">
                        <label htmlFor="sub_title" className="block text-sm font-medium text-gray-700">Subtitle</label>
                        <input
                            type="text"
                            name="sub_title"
                            value={formData.sub_title}
                            onChange={handleInputChange}
                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>

                    <div className="mb-4">
                        <label htmlFor="description" className="block text-sm font-medium text-gray-700">Description</label>
                        <textarea
                            name="description"
                            value={formData.description}
                            onChange={handleInputChange}
                            rows="3"
                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>

                    <div className="mb-4">
                        <label htmlFor="date" className="block text-sm font-medium text-gray-700">Start Date</label>
                        <input
                            type="date"
                            name="date"
                            value={formData.date}
                            onChange={handleInputChange}
                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>

                    <div className="mb-4">
                        <label htmlFor="auther_name" className="block text-sm font-medium text-gray-700">Author Name</label>
                        <input
                        type="text"
                        name="auther_name"
                        value={formData.auther_name}
                        onChange={handleInputChange}
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    
                    <div className="mb-4">
                        <label htmlFor="image_link" className="block text-sm font-medium text-gray-700">Github URL</label>
                        <input
                            type="text"
                            name="image_link"
                            value={formData.image_link}
                            onChange={handleInputChange}
                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>

                    <div className="mb-4">
                        <label htmlFor="color_code" className="block text-sm font-medium text-gray-700">Color code</label>
                        <input
                            type="text"
                            name="color_code"
                            value={formData.color_code}
                            onChange={handleInputChange}
                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>

                    <div className="flex justify-between mt-6">
                        <button
                            type="button"
                            onClick={onClose}
                            className="px-6 py-2 text-sm font-medium text-gray-700 bg-gray-200 rounded-lg hover:bg-gray-300 focus:outline-none"
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className="px-6 py-2 text-sm font-medium text-white bg-blue-600 rounded-lg hover:bg-blue-700 focus:outline-none"
                        >
                            Save Changes
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default EditBlogModal;
