import { FaDownload } from "react-icons/fa";
import { MdWork, MdSchool } from "react-icons/md";
import { BsLightningChargeFill } from "react-icons/bs";

const Resume = () => {
  return (
    <div className="bg-gradient-to-r from-blue-900 to-black relative text-white flex flex-col items-center py-10">
      {/* Title */}
      <h1 className="text-4xl md:text-5xl font-bold text-blue-400 animate-pulse text-center mb-8">Professional Resume</h1>
      <div className="w-16 h-1 bg-purple-500 mb-6"></div>

      {/* Download Button */}
      <button className="flex items-center gap-2 bg-purple-600 px-6 py-2 rounded-lg shadow-lg hover:bg-purple-700 transition">
        <FaDownload /> Download Resume PDF
      </button>

      {/* Resume Sections */}
      <div className="grid md:grid-cols-2 gap-6 mt-10 w-11/12 max-w-5xl">
        {/* Experience Section */}
        <div className="bg-gray-800 p-6 rounded-lg shadow-lg">
          <h2 className="text-xl font-semibold flex items-center gap-2 mb-4">
            <MdWork className="text-orange-400" /> Experience
          </h2>
          <div>
            <h3 className="text-lg font-bold text-blue-400">Senior Software Engineer</h3>
            <p className="text-sm text-gray-400">Tech Corp | 2021 - Present</p>
            <ul className="list-disc list-inside text-gray-300 mt-2 text-sm">
              <li>Led development of cloud-native applications</li>
              <li>Implemented microservices architecture</li>
              <li>Managed team of 5 developers</li>
            </ul>
          </div>
          <div className="mt-4">
            <h3 className="text-lg font-bold text-blue-400">Software Engineer</h3>
            <p className="text-sm text-gray-400">Innovation Labs | 2019 - 2021</p>
            <ul className="list-disc list-inside text-gray-300 mt-2 text-sm">
              <li>Developed full-stack web applications</li>
              <li>Optimized database performance</li>
              <li>Implemented CI/CD pipelines</li>
            </ul>
          </div>
        </div>

        {/* Education & Certifications Section */}
        <div className="bg-gray-800 p-6 rounded-lg shadow-lg">
          <h2 className="text-xl font-semibold flex items-center gap-2 mb-4">
            <MdSchool className="text-yellow-400" /> Education & Certifications
          </h2>
          <div>
            <h3 className="text-lg font-bold text-purple-400">Master of Computer Science</h3>
            <p className="text-sm text-gray-400">Tech University | 2017 - 2019</p>
            <p className="text-sm text-gray-300">Focus: Artificial Intelligence</p>
          </div>
          <div className="mt-4">
            <h3 className="text-lg font-bold text-purple-400">AWS Certified Solutions Architect</h3>
            <p className="text-sm text-gray-400">Amazon Web Services | 2022</p>
          </div>
          <div className="mt-4">
            <h3 className="text-lg font-bold text-purple-400">Google Cloud Professional</h3>
            <p className="text-sm text-gray-400">Google | 2021</p>
          </div>
        </div>
      </div>

      {/* Technical Skills Section */}
      <div className="bg-gray-800 p-6 rounded-lg shadow-lg w-11/12 max-w-5xl mt-6">
        <h2 className="text-xl font-semibold flex items-center gap-2 mb-4">
          <BsLightningChargeFill className="text-yellow-400" /> Technical Skills
        </h2>
        <div className="grid md:grid-cols-3 gap-4">
          {/* Frontend */}
          <div>
            <h3 className="text-lg font-bold text-gray-300">Frontend</h3>
            <p className="text-sm text-gray-400">React</p>
            <div className="w-full bg-gray-700 rounded-full h-2 mt-1">
              <div className="bg-purple-500 h-2 rounded-full w-[95%]"></div>
            </div>
            <p className="text-sm text-gray-400 mt-2">JavaScript</p>
            <div className="w-full bg-gray-700 rounded-full h-2 mt-1">
              <div className="bg-purple-500 h-2 rounded-full w-[90%]"></div>
            </div>
          </div>

          {/* Backend */}
          <div>
            <h3 className="text-lg font-bold text-gray-300">Backend</h3>
            <p className="text-sm text-gray-400">Node.js</p>
            <div className="w-full bg-gray-700 rounded-full h-2 mt-1">
              <div className="bg-green-500 h-2 rounded-full w-[88%]"></div>
            </div>
            <p className="text-sm text-gray-400 mt-2">Python</p>
            <div className="w-full bg-gray-700 rounded-full h-2 mt-1">
              <div className="bg-green-500 h-2 rounded-full w-[85%]"></div>
            </div>
          </div>

          {/* DevOps */}
          <div>
            <h3 className="text-lg font-bold text-gray-300">DevOps</h3>
            <p className="text-sm text-gray-400">Docker</p>
            <div className="w-full bg-gray-700 rounded-full h-2 mt-1">
              <div className="bg-purple-500 h-2 rounded-full w-[92%]"></div>
            </div>
            <p className="text-sm text-gray-400 mt-2">AWS</p>
            <div className="w-full bg-gray-700 rounded-full h-2 mt-1">
              <div className="bg-purple-500 h-2 rounded-full w-[87%]"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Resume;
