import axios from 'axios';

const API = axios.create({ baseURL: `${process.env.REACT_APP_BACKEND_URL}/api` });

export const login = (data) => API.post('/login', data);

// export const userdata = (data) => API.post('/userdata', data); 

// Projects
export const myprojects = (data) => API.post('/allprojects', data);
export const addproject = (data) => API.post('/addprojects', data);
export const editproject = (id, data) => API.put(`/editprojects/${id}`, data);

// Blogs
export const myblogs = (data) => API.post('/allBlogs', data);
export const addBlog = (data) => API.post('/addBlogs', data);
export const editblog = (id, data) => API.put(`/editblogs/${id}`, data);


// Contacts
export const allcontacts = (data) => API.get('/contacts', data);
export const addcontact = (data) => API.post('/addContact', data);



// Users data
export const PersonalInfoapi = (id, data) => API.put(`/personalinfo/${id}`, data);
export const PersonalInfoget = (id) => API.get(`/personalinfoget/${id}`);
export const userdata = (data) => API.post('/userdata', data); 
export const addEducation = (userId, educationData) => API.post(`/add-education/${userId}`, educationData);
export const deleteeducation = (userId, id) => API.post(`/delete-education/${userId}/${id}`);
export const addExperience = (userId, experienceData) => API.post(`/add-experience/${userId}`, experienceData);
export const deleteexperience = (userId, id) => API.post(`/delete-experience/${userId}/${id}`);
export const addFrontend = (userId, frontendData) => API.post(`/add-frontend/${userId}`, frontendData);
export const deletefrontend = (userId, id) => API.post(`/delete-frontend/${userId}/${id}`);
export const addbackend = (userId, backendData) => API.post(`/add-backend/${userId}`, backendData);
export const deletebackend = (userId, id) => API.post(`/delete-backend/${userId}/${id}`);
export const addSoftskill = (userId, softskillData) => API.post(`/add-softskill/${userId}`, softskillData);
export const deletesoftskill = (userId, id) => API.post(`/delete-softskill/${userId}/${id}`);
export const addSocialMedia = (userId, SocialMediaData) => API.post(`/add-socialmedia/${userId}`, SocialMediaData);
export const deleteSocialMedia = (userId, id) => API.post(`/delete-socialmedia/${userId}/${id}`);