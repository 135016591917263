import React from 'react'

const About = () => {
    return (
        <div className='bg-gradient-to-r from-blue-900 to-black shadow-lg'>
            <div className="container mx-auto py-12 px-6">
                <h2 className="text-4xl md:text-5xl font-bold text-blue-400 animate-pulse text-center mb-8">About Me</h2>

                <div className="flex flex-col md:flex-row justify-between gap-8 text-white">
                    <div className="md:w-1/2">
                        <h3 className="text-xl font-semibold">Who I Am</h3>
                        <p className="mt-4">A passionate software engineer with 5+ years of experience in building scalable applications and solving complex problems. I specialize in full-stack development, cloud architecture, and engineering leadership.</p>
                        <p className="mt-4">My mission is to create innovative solutions that make a real impact while maintaining clean, efficient, and maintainable code.</p>
                    </div>
                    <div className="md:w-1/2 p-6 rounded-lg shadow-lg">
                        <h3 className="text-xl font-semibold">Experience Timeline</h3>
                        <div className="mt-4 space-y-6">
                            <div className="relative pl-6 border-l-4 border-purple-500">
                                <span className="absolute left-[-9px] w-4 h-4 bg-purple-500 rounded-full"></span>
                                <h4 className="font-bold">Senior Software Engineer</h4>
                                <p className="text-white-600">Tech Corp | 2021 - Present</p>
                                <p className="text-sm text-white-500">Leading development of scalable cloud solutions</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-4 gap-6 mt-12">
                    <div className="bg-white shadow-md rounded-lg p-6 text-center transform transition duration-300 hover:scale-105 hover:shadow-lg hover:bg-red-100 cursor-pointer">
                        <span className="text-red-500 text-3xl">🎯</span>
                        <h4 className="font-bold mt-4">Problem Solver</h4>
                        <p className="text-sm text-gray-500">Analytical approach to complex challenges</p>
                    </div>
                    <div className="bg-white shadow-md rounded-lg p-6 text-center transform transition duration-300 hover:scale-105 hover:shadow-lg hover:bg-red-100 cursor-pointer">
                        <span className="text-yellow-500 text-3xl">💡</span>
                        <h4 className="font-bold mt-4">Innovator</h4>
                        <p className="text-sm text-gray-500">Creative solutions and fresh perspectives</p>
                    </div>
                    <div className="bg-white shadow-md rounded-lg p-6 text-center transform transition duration-300 hover:scale-105 hover:shadow-lg hover:bg-red-100 cursor-pointer">
                        <span className="text-blue-500 text-3xl">🤝</span>
                        <h4 className="font-bold mt-4">Team Player</h4>
                        <p className="text-sm text-gray-500">Strong collaboration and leadership</p>
                    </div>
                    <div className="bg-white shadow-md rounded-lg p-6 text-center transform transition duration-300 hover:scale-105 hover:shadow-lg hover:bg-red-100 cursor-pointer">
                        <span className="text-green-500 text-3xl">📚</span>
                        <h4 className="font-bold mt-4">Continuous Learner</h4>
                        <p className="text-sm text-gray-500">Always expanding knowledge base</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About