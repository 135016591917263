import React, { useState, useEffect } from "react";
import { PersonalInfoapi, PersonalInfoget, addExperience, deleteexperience } from "../../../../services/api";
import { useSelector } from "react-redux";
import { IoTrashBin } from "react-icons/io5";
import { MdAddCircle } from "react-icons/md";
import { FaEdit } from "react-icons/fa";
import { TiCancel } from "react-icons/ti";



const Experience = () => {
    const userId = useSelector((state) => state.auth.user?.currentUser?._id) || null;
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [experiences, setExperiences] = useState([]);
    const [showEditExpModal, setShowEditExpModal] = useState(false);
    const [selectedExp, setSelectedExp] = useState(null);
    const [showAddModal, setShowAddModal] = useState(false);
    
    const [formData, setFormData] = useState({
        profile: "",
        e_c_name: "",
        startDate: "",
        endDate: "",
        preset: "",
        e_description: "",
        address: "",
    });

    useEffect(() => {
        if (!userId) return;
        const fetchExpData = async () => {
            setLoading(true);
            try {
                const response = await PersonalInfoget(userId);
                setExperiences(response.data.user.experience);
            } catch (err) {
                setError("Failed to fetch user data.");
            } finally {
                setLoading(false);
            }
        };
        fetchExpData();
    }, [userId]);

    useEffect(() => {
        if (selectedExp) {
            setFormData(selectedExp);
        }
    }, [selectedExp]);

    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleUpdateExperience = async () => {
        try {
            const updatedData = {
                experience: experiences.map((exp) =>
                    exp._id === selectedExp._id ? { ...exp, ...formData } : exp
                ),
            };

            await PersonalInfoapi(userId, updatedData);
            setExperiences(updatedData.experience);
            setShowEditExpModal(false);
            setSelectedExp(null);
        } catch (error) {
            console.error("Error updating experience:", error);
        }
    };

    const handleAddExperience = async () => {
        if (!userId) {
            console.error("User ID not found");
            return;
        }
        try {
            setLoading(true);
            console.log("formData: ", formData);
            const response = await addExperience(userId, formData);
            setExperiences(response.data.experience);
            setShowAddModal(false);
            setFormData({
                profile: "",
                e_c_name: "",
                startDate: "",
                endDate: "",
                preset: "",
                e_description: "",
                address: "",
            });
        } catch (error) {
            console.error("Error adding experience:", error);
        } finally {
            setLoading(false);
        }
    };

    const handledeleteinforow = async (id) => {
        if (!userId) {
            console.error("User ID not found");
            return;
        }
        try {
            await deleteexperience(userId, id);
            setExperiences((prevExperiences) => prevExperiences.filter((exp) => exp._id !== id));
            setShowEditExpModal(false);
            
        } catch (error) {
            console.error("Error deleting experience:", error);
        }
    };
    
    

    return (
        <div>
            <div className="p-4 flex items-center justify-between text-white border-2 border-gray-200 border-dashed rounded-lg dark:border-gray-700">
                <h1 className="text-2xl xl:text-3xl font-extrabold">Experience</h1>
                <button
                    type="button"
                    className="flex items-center justify-center gap-2 text-white bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5"
                    onClick={() => setShowAddModal(true)}
                >
                    <svg className="w-6 h-6 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 12h14m-7 7V5" />
                    </svg>
                    Add Experience
                </button>
            </div>

            <div className="p-4 mt-3 border-2 border-gray-200 border-dashed rounded-lg dark:border-gray-700">
                <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                    <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                            <tr>
                                <th className="px-6 py-3">Profile</th>
                                <th className="px-6 py-3">E C Name</th>
                                <th className="px-6 py-3">Present</th>
                                <th className="px-6 py-3">Description</th>
                                <th className="px-6 py-3">Start Date</th>
                                <th className="px-6 py-3">End Date</th>
                                <th className="px-6 py-3">Address</th>
                            </tr>
                        </thead>
                        <tbody>
                            {experiences.map((experience) => (
                                <tr key={experience._id} onClick={() => { setSelectedExp(experience); setShowEditExpModal(true);}} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                    <td className="px-6 py-3">{experience.profile}</td>
                                    <td className="px-6 py-3">{experience.e_c_name}</td>
                                    <td className="px-6 py-3">{experience.preset}</td>
                                    <td className="px-6 py-3">{experience.e_description}</td>
                                    <td className="px-6 py-3">{experience.startDate}</td>
                                    <td className="px-6 py-3">{experience.endDate}</td>
                                    <td className="px-6 py-3">{experience.address}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>

            {showEditExpModal && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                    <div className="bg-white p-5 rounded-lg w-1/3">
                        <h2 className="text-xl font-bold mb-4">Edit Experience</h2>
                        <input type="text" name="profile" value={formData.profile} placeholder="Profile Name" className="block w-full p-2 border rounded mb-2" onChange={handleInputChange} />
                        <input type="text" name="e_c_name" value={formData.e_c_name} placeholder="Company Name" className="block w-full p-2 border rounded mb-2" onChange={handleInputChange} />
                        <input type="text" name="preset" value={formData.preset} placeholder="Present" className="block w-full p-2 border rounded mb-2" onChange={handleInputChange} />
                        <input type="text" name="e_description" value={formData.e_description} placeholder="Discription" className="block w-full p-2 border rounded mb-2" onChange={handleInputChange} />
                        <input type="date" name="startDate" value={formData.startDate} className="block w-full p-2 border rounded mb-2" onChange={handleInputChange} />
                        <input type="date" name="endDate" value={formData.endDate} className="block w-full p-2 border rounded mb-2" onChange={handleInputChange} />
                        <input type="text" name="address" value={formData.address} placeholder="Company Address" className="block w-full p-2 border rounded mb-2" onChange={handleInputChange} />

                        <div className="flex justify-between gap-2 mt-4">
                            <button className="bg-gray-500 text-white px-4 py-2 rounded flex gap-2 items-center justify-center" onClick={() => { setShowEditExpModal(false); setSelectedExp(null); }}><TiCancel /> Cancel</button>
                            <button className="bg-green-500 text-white px-4 py-2 rounded flex gap-2 items-center justify-center" onClick={handleUpdateExperience}><FaEdit/> Update</button>
                            <button className="bg-red-500 flex gap-2 items-center justify-center text-white px-4 py-2 rounded" onClick={() => { handledeleteinforow(formData._id); }}> <IoTrashBin/> Delete</button>
                        </div>
                    </div>
                </div>
            )}


            {(showAddModal) && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                    <div className="bg-white p-5 rounded-lg w-1/3">
                        <h2 className="text-xl font-bold mb-4">{showEditExpModal ? "Edit Experience" : "Add Experience"}</h2>
                        <input type="text" name="profile" placeholder="Profile Name" className="block w-full p-2 border rounded mb-2" onChange={handleInputChange} />
                        <input type="text" name="e_c_name" placeholder="Company Name" className="block w-full p-2 border rounded mb-2" onChange={handleInputChange} />
                        <input type="text" name="preset" placeholder="Present" className="block w-full p-2 border rounded mb-2" onChange={handleInputChange} />
                        <input type="text" name="e_description" placeholder="Discription" className="block w-full p-2 border rounded mb-2" onChange={handleInputChange} />
                        <input type="date" name="startDate" className="block w-full p-2 border rounded mb-2" onChange={handleInputChange} />
                        <input type="date" name="endDate" className="block w-full p-2 border rounded mb-2" onChange={handleInputChange} />
                        <input type="text" name="address" placeholder="Company Address" className="block w-full p-2 border rounded mb-2" onChange={handleInputChange} />
                        <div className="flex justify-between gap-2 mt-4">
                            <button className="bg-red-500 text-white px-4 py-2 rounded flex gap-2 items-center justify-center" onClick={() => { setShowEditExpModal(false); setShowAddModal(false); }}> <TiCancel />Close</button>
                            <button className="bg-green-500 text-white px-4 py-2 rounded flex gap-2 items-center justify-center" onClick={handleAddExperience}> <MdAddCircle/> Add Experience</button>
                        </div>
                    </div>
                </div>
            )}

        </div>
    );
};

export default Experience;
