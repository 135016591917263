import React from 'react'

const Blogs = () => {
    return (
        <div className='bg-gray-900 flex flex-col items-center justify-center h-screen px-4 bg-gradient-to-r from-blue-900 to-black shadow-lg mt-4'  >
            <h1 className="text-4xl md:text-5xl font-bold text-blue-400 animate-pulse">Blogs</h1>
            <div className='container p-4' style={{ height: 700, overflowY: 'auto' }}>
                <div className="sm:grid lg:grid-cols-3 sm:grid-cols-2 gap-10">
                    <div
                        className="hover:bg-gray-900 hover:text-white bg-white cursor-pointer transition duration-300 max-w-sm rounded overflow-hidden shadow-lg">
                        <div className="py-4 px-8">
                            <a href="#">
                                <h4 className="text-lg mb-3 font-semibold" style={{ width: '300px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>How to be effective at working remotely?</h4>
                            </a>
                            <p className="mb-2 text-sm text-gray-600" style={{ width: '300px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>Lorem Ipsum is simply dummy text of the printing and typesetting
                                industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>

                            <img src="https://images.pexels.com/photos/461077/pexels-photo-461077.jpeg?auto=compress&amp;cs=tinysrgb&amp;dpr=1&amp;w=500" className="w-100" />

                            <hr className="mt-4" />
                            <span className="text-xs">By: </span>
                            &nbsp;<span className="text-xs text-gray-600">theritesh.in</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Blogs