import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { userdata } from "../../../services/api";

const Profile = () => {
  const userId = useSelector((state) => state.auth.user?.currentUser?._id) || null;
  const [user, setUser] = useState(null);
  const [step, setStep] = useState(0);

  const fetchUserData = async (id) => {
    try {
      const response = await userdata({ userId: id });
      setUser(response.data);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  useEffect(() => {
    if (userId) {
      fetchUserData(userId);
    }
  }, [userId]);

  const sections = [
    "details",
    "education",
    "experience",
    "frontend",
    "backend",
    "softskill",
    "socialmedia",
  ];

  const nextStep = () => {
    setStep((prev) => (prev < sections.length - 1 ? prev + 1 : prev));
  };

  const prevStep = () => {
    setStep((prev) => (prev > 0 ? prev - 1 : prev));
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-900">
      <div className="p-6 border-2 border-gray-200 border-dashed rounded-lg dark:border-gray-700 text-white w-100 bg-gray-800 shadow-lg">
        <h1 className="text-3xl font-extrabold text-center">Profile</h1>
        <div className="mt-4 space-y-4">
          {user && (
            <div>
              {step === 0 && (
                <div className="p-4 bg-gray-700 rounded-lg shadow">
                  <h2 className="text-xl font-bold">{user.name}</h2>
                  <p>Role: {user.role}</p>
                  <p>Company: {user.c_name}</p>
                  <p>Description: {user.description}</p>
                </div>
              )}
              {step === 1 && (
                <div className="p-4 bg-gray-700 rounded-lg shadow">
                  <h2 className="text-xl font-bold">Education</h2>
                  {user.education?.map((edu) => (
                    <div key={edu._id}>
                      <p>Course: {edu.course_name}</p>
                      <p>Institute: {edu.institute}</p>
                      <p>Specification: {edu.specification}</p>
                    </div>
                  ))}
                </div>
              )}
              {step === 2 && (
                <div className="p-4 bg-gray-700 rounded-lg shadow">
                  <h2 className="text-xl font-bold">Experience</h2>
                  {user.experience?.map((exp) => (
                    <div key={exp._id}>
                      <p>Company: {exp.e_c_name}</p>
                      <p>Profile: {exp.profile}</p>
                      <p>Description: {exp.e_description}</p>
                    </div>
                  ))}
                </div>
              )}
              {step === 3 && (
                <div className="p-4 bg-gray-700 rounded-lg shadow">
                  <h2 className="text-xl font-bold">Frontend Skills</h2>
                  {user.frontend?.map((skill) => (
                    <p key={skill._id}>{skill.f_name} - Rating: {skill.rating}</p>
                  ))}
                </div>
              )}
              {step === 4 && (
                <div className="p-4 bg-gray-700 rounded-lg shadow">
                  <h2 className="text-xl font-bold">Backend Skills</h2>
                  {user.backend?.map((skill) => (
                    <p key={skill._id}>{skill.b_name} - Rating: {skill.rating}</p>
                  ))}
                </div>
              )}
              {step === 5 && (
                <div className="p-4 bg-gray-700 rounded-lg shadow">
                  <h2 className="text-xl font-bold">Soft Skills</h2>
                  {user.softskill?.map((skill) => (
                    <p key={skill._id}>{skill.ss_name}</p>
                  ))}
                </div>
              )}
              {step === 6 && (
                <div className="p-4 bg-gray-700 rounded-lg shadow">
                  <h2 className="text-xl font-bold">Social Media</h2>
                  {user.socialmedia?.map((social) => (
                    <p key={social._id}><a href={social.url} target="_blank" rel="noopener noreferrer">{social.socialmedia_name}</a></p>
                  ))}
                </div>
              )}
            </div>
          )||(step === 0 && <div className="p-4 bg-gray-700 rounded-lg shadow">No data found</div>)}
        </div>
        <div className="mt-4 flex justify-between gap-6">
          <button onClick={prevStep} className="px-4 py-2 bg-gray-500 rounded" disabled={step === 0}>Previous</button>
          <button onClick={nextStep} className="px-4 py-2 bg-blue-500 rounded" disabled={step === sections.length - 1}>Next</button>
        </div>
      </div>
    </div>
  );
};

export default Profile;
