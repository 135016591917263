import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Nav from './components/client/nav/Nav';
import Home from './components/client/home/Home';
import Footer from './components/client/Footer/Footer';
import Blogs from './components/client/Blogsection/Blogs';
import Project from './components/client/projects/Project';
import Contact from './components/client/about/Contact';
import DetailAbout from './components/client/about/DetailAbout';
import Login from './components/admin/auth/Login';
import Dashboard from './components/admin/auth/Dashboard';
import PrivateRoute from './PrivateRoute';

function MainLayout() {
  const location = useLocation();
  const hideNavbar = location.pathname === "/dashboard";

  return (
    <div>
      {!hideNavbar && <Nav />}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/detailabout" element={<DetailAbout />} />
        <Route path="/blogs" element={<Blogs />} />
        <Route path="/projects" element={<Project />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/login" element={<Login />} />
        <Route
          path="/dashboard"
          element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          }
        />
      </Routes>
      {!hideNavbar && <Footer />}
    </div>
  );
}

function App() {
  return (
    <Router>
      <MainLayout />
    </Router>
  );
}

export default App;
