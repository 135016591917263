import React, { useState, useEffect } from "react";
import { PersonalInfoapi, PersonalInfoget, addSocialMedia, deleteSocialMedia } from "../../../../services/api";
import { useSelector } from "react-redux";
import { IoTrashBin } from "react-icons/io5";
import { MdAddCircle } from "react-icons/md";
import { FaEdit } from "react-icons/fa";
import { TiCancel } from "react-icons/ti";


const SocialMedia = () => {
  const userId = useSelector((state) => state.auth.user?.currentUser?._id) || null;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [socialmedias, setSocialMedia] = useState([]);
  const [showEditSocialMediaModal, setShowEditSocialMediaModal] = useState(false);
  const [selectedSocialMedia, setSelectedSocialMedia] = useState(null);
  const [showAddModal, setShowAddModal] = useState(false);

  const [formData, setFormData] = useState({
    socialmedia_name: "",
    url: ""
  });

  useEffect(() => {
    if (!userId) return;
    const fetchExpData = async () => {
      setLoading(true);
      try {
        const response = await PersonalInfoget(userId);
        setSocialMedia(response.data.user.socialmedia);
      } catch (err) {
        setError("Failed to fetch user data.");
      } finally {
        setLoading(false);
      }
    };
    fetchExpData();
  }, [userId]);

  useEffect(() => {
    if (selectedSocialMedia) {
      setFormData(selectedSocialMedia);
    }
  }, [selectedSocialMedia]);

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleUpdateSM = async () => {
    try {
      const updatedData = {
        socialmedia: socialmedias.map((sm) =>
          sm._id === selectedSocialMedia._id ? { ...sm, ...formData } : sm
        ),
      };

      await PersonalInfoapi(userId, updatedData);
      setSocialMedia(updatedData.socialmedia);
      setShowEditSocialMediaModal(false);
      setSelectedSocialMedia(null);
    } catch (error) {
      console.error("Error updating socialmedia:", error);
    }
  };

  const handleAddSM = async () => {
    if (!userId) {
      console.error("User ID not found");
      return;
    }
    try {
      setLoading(true);
      console.log("formData: ", formData);
      const response = await addSocialMedia(userId, formData);
      setSocialMedia(response.data.socialmedia);
      setShowAddModal(false);
      setFormData({
        socialmedia_name: "",
        url: ""
      });
    } catch (error) {
      console.error("Error adding SocialMedia:", error);
    } finally {
      setLoading(false);
    }
  };

  const handledeleteinforow = async (id) => {
    if (!userId) {
      console.error("User ID not found");
      return;
    }
    try {
      await deleteSocialMedia(userId, id);
      setSocialMedia((prevSocialMedia) => prevSocialMedia.filter((sm) => sm._id !== id));
      setShowEditSocialMediaModal(false);

    } catch (error) {
      console.error("Error deleting softskill:", error);
    }
  };



  return (
    <div>
      <div className="p-4 flex items-center justify-between text-white border-2 border-gray-200 border-dashed rounded-lg dark:border-gray-700">
        <h1 className="text-2xl xl:text-3xl font-extrabold">Social Media</h1>
        <button
          type="button"
          className="flex items-center justify-center gap-2 text-white bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5"
          onClick={() => setShowAddModal(true)}
        >
          <svg className="w-6 h-6 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 12h14m-7 7V5" />
          </svg>
          Add Social Media
        </button>
      </div>

      <div className="p-4 mt-3 border-2 border-gray-200 border-dashed rounded-lg dark:border-gray-700">
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
          <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th className="px-6 py-3">Social Media Name</th>
                <th className="px-6 py-3">Url</th>
              </tr>
            </thead>
            <tbody>
              {socialmedias.map((socialmedia) => (
                <tr key={socialmedia._id} onClick={() => { setSelectedSocialMedia(socialmedia); setShowEditSocialMediaModal(true); }} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                  <td className="px-6 py-3">{socialmedia.socialmedia_name}</td>
                  <td className="px-6 py-3 flex items-center">{socialmedia.url}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {showEditSocialMediaModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-5 rounded-lg w-1/3">
            <h2 className="text-xl font-bold mb-4">Edit Social Media</h2>
            <input type="text" name="socialmedia_name" value={formData.socialmedia_name} placeholder="Backend Name" className="block w-full p-2 border rounded mb-2" onChange={handleInputChange} />
            <input type="text" name="url" value={formData.url} placeholder="Url" className="block w-full p-2 border rounded mb-2" onChange={handleInputChange} />

            <div className="flex justify-between gap-2 mt-4">
              <button className="bg-gray-500 text-white px-4 py-2 rounded flex gap-2 items-center justify-center" onClick={() => { setShowEditSocialMediaModal(false); setSelectedSocialMedia(null); }}><TiCancel /> Cancel</button>
              <button className="bg-green-500 text-white px-4 py-2 rounded flex gap-2 items-center justify-center" onClick={handleUpdateSM}><FaEdit /> Update</button>
              <button className="bg-red-500 flex gap-2 items-center justify-center text-white px-4 py-2 rounded" onClick={() => { handledeleteinforow(formData._id); }}> <IoTrashBin /> Delete</button>
            </div>
          </div>
        </div>
      )}


      {(showAddModal) && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-5 rounded-lg w-1/3">
            <h2 className="text-xl font-bold mb-4">{showEditSocialMediaModal ? "Edit Social Media" : "Add Social Media"}</h2>
            <input type="text" name="socialmedia_name" placeholder="Social Media Name" className="block w-full p-2 border rounded mb-2" onChange={handleInputChange} />
            <input type="text" name="url" placeholder="Url" className="block w-full p-2 border rounded mb-2" onChange={handleInputChange} />
            <div className="flex justify-between gap-2 mt-4">
              <button className="bg-red-500 text-white px-4 py-2 rounded flex gap-2 items-center justify-center" onClick={() => { setShowEditSocialMediaModal(false); setShowAddModal(false); }}> <TiCancel />Close</button>
              <button className="bg-green-500 text-white px-4 py-2 rounded flex gap-2 items-center justify-center" onClick={handleAddSM}> <MdAddCircle /> Add Soft Skills</button>
            </div>
          </div>
        </div>
      )}

    </div>
  );
};

export default SocialMedia;
