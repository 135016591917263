import { useState } from "react";
import { FaLinkedin, FaGithub, FaTwitter } from "react-icons/fa";
import "./About.css";
import { addcontact } from "../../../services/api"; // Ensure correct path

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
    contact_no: "", // Fix: Add missing contact_no
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      await addcontact(formData); // Ensure `contact_no` is sent
      alert("Message sent successfully!");
      setFormData({ name: "", email: "", subject: "", message: "", contact_no: "" });
    } catch (error) {
      console.error("Error sending message:", error.response?.data || error.message);
      alert("Failed to send message. Please try again.");
    }

    setIsSubmitting(false);
  };

  return (
    <div className='contacts bg-gray-900 flex flex-col items-center justify-center h-screen px-4 bg-gradient-to-r from-blue-900 to-black shadow-lg mt-4'>
      <h2 className="text-4xl md:text-5xl font-bold text-blue-400 animate-pulse text-center mb-8">
        Get In Touch
      </h2>
      <div className="w-20 h-1 bg-purple-600 mb-6"></div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 w-full max-w-4xl">
        {/* Contact Form */}
        <div className="bg-gray-800 p-6 rounded-lg shadow-lg text-white">
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              name="name"
              placeholder="Name"
              value={formData.name}
              onChange={handleChange}
              className="w-full p-3 mb-3 border rounded-lg text-black"
              required
            />
            <input
              type="email"
              name="email"
              placeholder="Email"
              value={formData.email}
              onChange={handleChange}
              className="w-full p-3 mb-3 border rounded-lg text-black"
              required
            />
            <input
              type="text"
              name="subject"
              placeholder="Subject"
              value={formData.subject}
              onChange={handleChange}
              className="w-full p-3 mb-3 border rounded-lg text-black"
              required
            />
            <textarea
              name="message"
              placeholder="Message"
              value={formData.message}
              onChange={handleChange}
              className="w-full p-3 mb-3 border rounded-lg h-24 text-black"
              required
            ></textarea>
            <input
              type="text"
              name="contact_no"
              placeholder="Phone Number"
              value={formData.contact_no}
              onChange={handleChange}
              className="w-full p-3 mb-3 border rounded-lg text-black"
              required
            />
            <button
              type="submit"
              className="w-full p-3 bg-purple-600 text-white rounded-lg"
              disabled={isSubmitting}
            >
              {isSubmitting ? "Sending..." : "Send Message"}
            </button>
          </form>
        </div>


        {/* Social Links & Newsletter */}
        <div className="flex flex-col gap-6 ">
          <div className="bg-gray-800 p-6 rounded-lg shadow-lg text-white h-full max-h-4xl ">
            <h3 className="text-xl font-semibold mb-3">Connect With Me</h3>
            <div className="flex flex-col gap-3">
              <a href="#" className="flex items-center gap-3 text-lg">
                <FaLinkedin className="text-blue-600 text-2xl" /> LinkedIn
              </a>
              <a href="#" className="flex items-center gap-3 text-lg">
                <FaGithub className="text-black text-2xl" /> GitHub
              </a>
              <a href="#" className="flex items-center gap-3 text-lg">
                <FaTwitter className="text-blue-400 text-2xl" /> Twitter
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
