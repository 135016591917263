import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import { Pagination, Autoplay } from 'swiper/modules';
import './About.css';

const Blog = () => {
    const blogs = [
        {
            title: 'E-Commerce Platform',
            description: 'A full-stack e-commerce solution with real-time inventory management and payment integration.',
            tags: ['React', 'Node.js'],
            liveLink: '#',
            githubLink: '#',
            bgColor: 'bg-blue-700',
            icon: '🌐',
        },
        {
            title: 'Fitness Tracker App',
            description: 'Mobile application for tracking workouts and nutrition with social features.',
            tags: ['React Native', 'Firebase'],
            liveLink: '#',
            githubLink: '#',
            bgColor: 'bg-pink-600',
            icon: '📱',
        },
        {
            title: 'AI Image Recognition',
            description: 'Deep learning model for real-time object detection and classification.',
            tags: ['Python', 'TensorFlow'],
            liveLink: '#',
            githubLink: '#',
            bgColor: 'bg-green-600',
            icon: '🤖',
        }, {
            title: 'E-Commerce Platform',
            description: 'A full-stack e-commerce solution with real-time inventory management and payment integration.',
            tags: ['React', 'Node.js'],
            liveLink: '#',
            githubLink: '#',
            bgColor: 'bg-blue-700',
            icon: '🌐',
        },
        {
            title: 'Fitness Tracker App',
            description: 'Mobile application for tracking workouts and nutrition with social features.',
            tags: ['React Native', 'Firebase'],
            liveLink: '#',
            githubLink: '#',
            bgColor: 'bg-pink-600',
            icon: '📱',
        },
        {
            title: 'AI Image Recognition',
            description: 'Deep learning model for real-time object detection and classification.',
            tags: ['Python', 'TensorFlow'],
            liveLink: '#',
            githubLink: '#',
            bgColor: 'bg-green-600',
            icon: '🤖',
        }, {
            title: 'E-Commerce Platform',
            description: 'A full-stack e-commerce solution with real-time inventory management and payment integration.',
            tags: ['React', 'Node.js'],
            liveLink: '#',
            githubLink: '#',
            bgColor: 'bg-blue-700',
            icon: '🌐',
        },
        {
            title: 'Fitness Tracker App',
            description: 'Mobile application for tracking workouts and nutrition with social features.',
            tags: ['React Native', 'Firebase'],
            liveLink: '#',
            githubLink: '#',
            bgColor: 'bg-pink-600',
            icon: '📱',
        },
        {
            title: 'AI Image Recognition',
            description: 'Deep learning model for real-time object detection and classification.',
            tags: ['Python', 'TensorFlow'],
            liveLink: '#',
            githubLink: '#',
            bgColor: 'bg-green-600',
            icon: '🤖',
        }
    ];

    return (
        <div className='bg-gray-900 flex flex-col items-center justify-center h-screen px-4 bg-gradient-to-r from-blue-900 to-black mt-2' style={{height: '70vh'}} >
            <div className="container mx-auto py-12 px-6">
                <h2 className="text-4xl md:text-5xl font-bold text-blue-400 animate-pulse text-center mb-8">
                    Blogs
                </h2>

                <div className="relative w-full max-w-6xl mx-auto px-4">
                    <Swiper
                        modules={[Pagination, Autoplay]}
                        slidesPerView={1}
                        breakpoints={{
                            640: { slidesPerView: 1 },
                            768: { slidesPerView: 2 },
                            1024: { slidesPerView: 3 },
                        }}
                        spaceBetween={20}
                        pagination={{ clickable: true }}
                        autoplay={{ delay: 5000, disableOnInteraction: false }} // Enables auto-scroll
                        loop={true}
                    >
                        {blogs.map((blog, index) => (
                            <SwiperSlide key={index}>
                                <div className="rounded-2xl shadow-lg overflow-hidden bg-white">
                                    <div className={`p-6 ${blog.bgColor} flex justify-center items-center text-5xl`}>
                                        {blog.icon}
                                    </div>
                                    <div className="p-5">
                                        <h3 className="text-xl font-semibold">{blog.title}</h3>
                                        <p className="text-gray-600 mt-2" style={{ width: '300px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{blog.description}</p>
                                        <div className="mt-3 flex flex-wrap gap-2">
                                            {blog.tags.map((tag, i) => (
                                                <span key={i} className="bg-gray-200 text-gray-800 px-2 py-1 text-xs rounded-lg">
                                                    {tag}
                                                </span>
                                            ))}
                                        </div>
                                        <div className="mt-4 flex gap-3">
                                            <a href={blog.liveLink} className="bg-blue-700 text-white px-4 py-2 rounded-lg text-sm">
                                                View Live
                                            </a>
                                            <a href={blog.githubLink} className="border border-blue-700 text-blue-700 px-4 py-2 rounded-lg text-sm">
                                                GitHub
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>

                </div>
            </div>
        </div>
    )
}

export default Blog;