import React from 'react';
import './Home.css';
import Avatar1 from '../../../assets/avtrat1.png';
import About from '../about/About';
import Technical from '../about/Technical';
import Project from '../about/Project';
import Blog from '../about/Blog';
import Resume from '../about/Resume';
import Contact from '../about/Contact';

const Home = () => {
  return (
    <>
      <div className='bg-gray-900 flex items-center justify-center h-screen px-4 bg-gradient-to-r from-blue-900 to-black  mt-4' >

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 w-full max-w-7xl items-center border-2 border-blue-500 rounded-lg shadow-sm dark:bg-gray-800 dark:border-blue-700 pt-5 animate-border" >
          {/* Column 1 */}
          <div className="relative w-full h-64  flex items-center justify-center overflow-hidden">
            <div className="absolute w-full h-full bg-[url('https://source.unsplash.com/1600x900/?technology,code')] opacity-10"></div>
            <div className="text-center z-10">
              <h1 className="text-4xl md:text-5xl font-bold text-blue-400 animate-pulse">Ritesh Pandey</h1>
              <p className="text-lg md:text-xl text-blue-300 mt-4">Software Engineer at <br/> Pin Services Pvt. Ltd.</p>
            </div>
          </div>

          {/* Column 2 */}
          <div className="w-full h-full flex items-center justify-center">
            <img src={Avatar1} alt="Avatar" className="w-full h-full object-cover" />
          </div>

        </div>
      </div>
      <About />
      <Technical />
      <Resume />
      <Project />
      <Blog />
      <Contact />
    </>
  );
};

export default Home;
