import { FaLinkedin, FaGithub, FaTwitter } from "react-icons/fa";
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer bg-gradient-to-r from-blue-900 to-black text-white py-12 px-6">
      <div className="max-w-7xl mx-auto grid grid-cols-1 md:grid-cols-4 gap-6">
        {/* Profile Section */}
        <div>
          <h2 className="text-white text-lg font-semibold">John Doe</h2>
          <p className="text-sm">Software Engineer & Full-Stack Developer</p>
          <div className="flex space-x-3 mt-3">
            <FaLinkedin className="text-gray-400 hover:text-white cursor-pointer" size={20} />
            <FaGithub className="text-gray-400 hover:text-white cursor-pointer" size={20} />
            <FaTwitter className="text-gray-400 hover:text-white cursor-pointer" size={20} />
          </div>
        </div>

        {/* Quick Links */}
        <div>
          <h3 className="text-white text-lg font-semibold">Quick Links</h3>
          <ul className="text-sm space-y-1 mt-2">
            <li><a href="#" className="hover:text-white">About</a></li>
            <li><a href="#" className="hover:text-white">Projects</a></li>
            <li><a href="#" className="hover:text-white">Blog</a></li>
            <li><a href="#" className="hover:text-white">Contact</a></li>
          </ul>
        </div>

        {/* Resources */}
        <div>
          <h3 className="text-white text-lg font-semibold">Resources</h3>
          <ul className="text-sm space-y-1 mt-2">
            <li><a href="#" className="hover:text-white">Resume</a></li>
            <li><a href="#" className="hover:text-white">Case Studies</a></li>
            <li><a href="#" className="hover:text-white">Certificates</a></li>
            <li><a href="#" className="hover:text-white">Privacy Policy</a></li>
          </ul>
        </div>

        {/* Newsletter */}
        <div>
          <h3 className="text-white text-lg font-semibold">Newsletter</h3>
          <p className="text-sm mt-2">Subscribe to receive updates and news</p>
          <div className="mt-3 flex">
            <input
              type="email"
              placeholder="Enter your email"
              className="bg-gray-800 text-white px-3 py-2 rounded-l-md outline-none w-full"
            />
            <button className="bg-purple-600 px-4 py-2 rounded-r-md text-white hover:bg-purple-700">
              Subscribe
            </button>
          </div>
        </div>
      </div>

      <div className="border-t border-gray-700 mt-6 pt-4 text-center text-sm">
        <p>© 2024 John Doe. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
