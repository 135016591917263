import React, { useState, useEffect } from "react";
import { PersonalInfoapi, PersonalInfoget, addSoftskill, deletesoftskill } from "../../../../services/api";
import { useSelector } from "react-redux";
import { IoTrashBin } from "react-icons/io5";
import { MdAddCircle } from "react-icons/md";
import { FaEdit } from "react-icons/fa";
import { TiCancel } from "react-icons/ti";


const SoftSkills = () => {
  const userId = useSelector((state) => state.auth.user?.currentUser?._id) || null;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [softskills, setSoftSkill] = useState([]);
  const [showEditSoftskillModal, setShowEditSoftskillModal] = useState(false);
  const [selectedsoftskill, setSelectedSoftskill] = useState(null);
  const [showAddModal, setShowAddModal] = useState(false);

  const [formData, setFormData] = useState({
    ss_name: "",
    colorcode: ""
  });

  useEffect(() => {
    if (!userId) return;
    const fetchExpData = async () => {
      setLoading(true);
      try {
        const response = await PersonalInfoget(userId);
        setSoftSkill(response.data.user.softskill);
      } catch (err) {
        setError("Failed to fetch user data.");
      } finally {
        setLoading(false);
      }
    };
    fetchExpData();
  }, [userId]);

  useEffect(() => {
    if (selectedsoftskill) {
      setFormData(selectedsoftskill);
    }
  }, [selectedsoftskill]);

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleUpdateSS = async () => {
    try {
      const updatedData = {
        softskill: softskills.map((ss) =>
          ss._id === selectedsoftskill._id ? { ...ss, ...formData } : ss
        ),
      };

      await PersonalInfoapi(userId, updatedData);
      setSoftSkill(updatedData.softskill);
      setShowEditSoftskillModal(false);
      setSelectedSoftskill(null);
    } catch (error) {
      console.error("Error updating softskill:", error);
    }
  };

  const handleAddSS = async () => {
    if (!userId) {
      console.error("User ID not found");
      return;
    }
    try {
      setLoading(true);
      console.log("formData: ", formData);
      const response = await addSoftskill(userId, formData);
      setSoftSkill(response.data.softskill);
      setShowAddModal(false);
      setFormData({
        ss_name: "",
        colorcode: ""
      });
    } catch (error) {
      console.error("Error adding softskill:", error);
    } finally {
      setLoading(false);
    }
  };

  const handledeleteinforow = async (id) => {
    if (!userId) {
      console.error("User ID not found");
      return;
    }
    try {
      await deletesoftskill(userId, id);
      setSoftSkill((prevSoftSkills) => prevSoftSkills.filter((ss) => ss._id !== id));
      setShowEditSoftskillModal(false);

    } catch (error) {
      console.error("Error deleting softskill:", error);
    }
  };



  return (
    <div>
      <div className="p-4 flex items-center justify-between text-white border-2 border-gray-200 border-dashed rounded-lg dark:border-gray-700">
        <h1 className="text-2xl xl:text-3xl font-extrabold">SoftSkills</h1>
        <button
          type="button"
          className="flex items-center justify-center gap-2 text-white bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5"
          onClick={() => setShowAddModal(true)}
        >
          <svg className="w-6 h-6 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 12h14m-7 7V5" />
          </svg>
          Add SoftSkills
        </button>
      </div>

      <div className="p-4 mt-3 border-2 border-gray-200 border-dashed rounded-lg dark:border-gray-700">
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
          <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th className="px-6 py-3">Soft Skill Name</th>
                <th className="px-6 py-3">Color Code</th>
              </tr>
            </thead>
            <tbody>
              {softskills.map((softskill) => (
                <tr key={softskill._id} onClick={() => { setSelectedSoftskill(softskill); setShowEditSoftskillModal(true); }} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                  <td className="px-6 py-3">{softskill.ss_name}</td>
                  <td className="px-6 py-3 flex items-center">{softskill.colorcode}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {showEditSoftskillModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-5 rounded-lg w-1/3">
            <h2 className="text-xl font-bold mb-4">Edit Soft Skills</h2>
            <input type="text" name="ss_name" value={formData.ss_name} placeholder="Backend Name" className="block w-full p-2 border rounded mb-2" onChange={handleInputChange} />
            <input type="text" name="colorcode" value={formData.colorcode} placeholder="colorcode" className="block w-full p-2 border rounded mb-2" onChange={handleInputChange} />

            <div className="flex justify-between gap-2 mt-4">
              <button className="bg-gray-500 text-white px-4 py-2 rounded flex gap-2 items-center justify-center" onClick={() => { setShowEditSoftskillModal(false); setSelectedSoftskill(null); }}><TiCancel /> Cancel</button>
              <button className="bg-green-500 text-white px-4 py-2 rounded flex gap-2 items-center justify-center" onClick={handleUpdateSS}><FaEdit /> Update</button>
              <button className="bg-red-500 flex gap-2 items-center justify-center text-white px-4 py-2 rounded" onClick={() => { handledeleteinforow(formData._id); }}> <IoTrashBin /> Delete</button>
            </div>
          </div>
        </div>
      )}


      {(showAddModal) && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-5 rounded-lg w-1/3">
            <h2 className="text-xl font-bold mb-4">{showEditSoftskillModal ? "Edit Soft Skills" : "Add Soft Skills"}</h2>
            <input type="text" name="ss_name" placeholder="Soft Skill Name" className="block w-full p-2 border rounded mb-2" onChange={handleInputChange} />
            <input type="text" name="colorcode" placeholder="Color Code" className="block w-full p-2 border rounded mb-2" onChange={handleInputChange} />
            <div className="flex justify-between gap-2 mt-4">
              <button className="bg-red-500 text-white px-4 py-2 rounded flex gap-2 items-center justify-center" onClick={() => { setShowEditSoftskillModal(false); setShowAddModal(false); }}> <TiCancel />Close</button>
              <button className="bg-green-500 text-white px-4 py-2 rounded flex gap-2 items-center justify-center" onClick={handleAddSS}> <MdAddCircle /> Add Soft Skills</button>
            </div>
          </div>
        </div>
      )}

    </div>
  );
};

export default SoftSkills;
