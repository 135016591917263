import React, { useEffect, useState } from 'react';
import { myprojects } from "../../../services/api";
import EditProjectModal from './EditProjectModal';  // Assuming you have these components
import AddProjectModal from './AddProjectModal';    // Assuming you have these components

const Projects = () => {
  const [myallprojects, setMyAllProjects] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [search, setSearch] = useState('');
  const [showEditModal, setShowEditModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);

  const fetchallprojectsData = async () => {
    try {
      const response = await myprojects();
      const myallprojects = response.data;
      setMyAllProjects(myallprojects);
      setFilteredProjects(myallprojects);
      console.log("myallprojects: ", myallprojects);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  useEffect(() => {
    fetchallprojectsData();
  }, []);

  useEffect(() => {
    if (search === '') {
      setFilteredProjects(myallprojects);
    } else {
      setFilteredProjects(
        myallprojects.filter((project) =>
          project.name.toLowerCase().includes(search.toLowerCase())
        )
      );
    }
  }, [search, myallprojects]);

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const handleEditClick = (projectId) => {
    const projectToEdit = myallprojects.find((project) => project._id === projectId);
    setSelectedProject(projectToEdit);
    setShowEditModal(true);
  };

  const handleAddClick = () => {
    setShowAddModal(true);
  };

  return (
    <div>
      <div className="p-4 flex items-center justify-between text-white border-2 border-gray-200 border-dashed rounded-lg dark:border-gray-700">
        <h1 className="flex items-center justify-center text-2xl xl:text-3xl font-extrabold">
          Projects
        </h1>

        <div class="pb-4">
            <label for="table-search" class="sr-only">Search</label>
            <div class="relative mt-1">
                <div class="absolute inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-3 pointer-events-none">
                    <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                    </svg>
                </div>
                <input type="text" id="table-search" class="block pt-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Search for items"
                  value={search}
                  onChange={handleSearchChange}
                />
            </div>
        </div>

        <button
          onClick={handleAddClick}
          type="button"
          className="flex items-center justify-center gap-2 text-white bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
        >
          <svg className="w-6 h-6 text-white-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 12h14m-7 7V5" />
          </svg>
          Add Project
        </button>
      </div>

      <div className="p-4 mt-3 border-2 border-gray-200 border-dashed rounded-lg dark:border-gray-700">
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3">Name</th>
                <th scope="col" className="px-6 py-3">Description</th>
                <th scope="col" className="px-6 py-3">Start-End Date</th>
                <th scope="col" className="px-6 py-3">Live Url</th>
                <th scope="col" className="px-6 py-3">Github Url</th>
                <th scope="col" className="px-6 py-3">Technologies</th>
              </tr>
            </thead>
            <tbody>
              {filteredProjects.map((project) => (
                <tr
                  key={project._id}
                  className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 border-gray-200 hover:bg-gray-50 dark:hover:bg-gray-600"
                  onClick={() => handleEditClick(project._id)}
                >
                  <td className="px-6 py-3">{project.name}</td>
                  <td className="px-6 py-3">{project.description}</td>
                  <td className="px-6 py-3">{`${project.startDate} - ${project.endDate}`}</td>
                  <td className="px-6 py-3">
                    <a href={project.live_url} target="_blank" rel="noopener noreferrer" className="text-blue-500">Live Site</a>
                  </td>
                  <td className="px-6 py-3">
                    <a href={project.github_url} target="_blank" rel="noopener noreferrer" className="text-blue-500">Github Repo</a>
                  </td>
                  <td className="px-6 py-3">{project.tech_name}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Edit Modal */}
      {showEditModal && selectedProject && (
        <EditProjectModal
        project={selectedProject}
        fetchallprojectsData={fetchallprojectsData}
        onClose={() => setShowEditModal(false)}
        />
      )}

      {/* Add Project Modal */}
      {showAddModal && (
        <AddProjectModal fetchallprojectsData={fetchallprojectsData} onClose={() => setShowAddModal(false)} />
      )}
    </div>
  );
};

export default Projects;
