import React, { useState } from 'react';
import { addBlog } from "../../../services/api"; // Import API call

const AddBlogModal = ({ fetchallblogsData, onClose }) => {
  const [formData, setFormData] = useState({
    title: '',
    sub_title: '',
    description: '',
    date: '',
    auther_name: '', // Added auther_name to form data
    image_link: '',
    color_code: '',
  });

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if the auther_name is empty
    if (!formData.auther_name.trim()) {
      alert('Author name is required');
      return; // Prevent form submission
    }

    try {
      // Call the API to add the blog
      const response = await addBlog(formData);
      console.log('Added blog:', response.data);

      // Optionally, show a success message
      alert('Blog added successfully!');
      await fetchallblogsData(); // Fetch all blogs after successful addition
      onClose(); // Close the modal
    } catch (error) {
      console.error('Error adding project:', error.response?.data || error.message);
      alert('Error adding project');
    }
  };

  return (
    <div className="fixed inset-0 bg-gray-500 bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white rounded-lg w-96 p-6">
        <h3 className="text-2xl font-semibold mb-4">Add Blog</h3>
        <form onSubmit={handleSubmit}>
          {/* Title Input */}
          <div className="mb-4">
            <label htmlFor="title" className="block text-sm font-medium text-gray-700">Title</label>
            <input
              type="text"
              name="title"
              value={formData.title}
              onChange={handleInputChange}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>

          {/* Subtitle Input */}
          <div className="mb-4">
            <label htmlFor="sub_title" className="block text-sm font-medium text-gray-700">Subtitle</label>
            <input
              type="text"
              name="sub_title"
              value={formData.sub_title}
              onChange={handleInputChange}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>

          {/* Description Input */}
          <div className="mb-4">
            <label htmlFor="description" className="block text-sm font-medium text-gray-700">Description</label>
            <textarea
              name="description"
              value={formData.description}
              onChange={handleInputChange}
              rows="3"
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>

          {/* Date Input */}
          <div className="mb-4">
            <label htmlFor="date" className="block text-sm font-medium text-gray-700">Date</label>
            <input
              type="date"
              name="date"
              value={formData.date}
              onChange={handleInputChange}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>

          {/* Author Name Input */}
          <div className="mb-4">
            <label htmlFor="auther_name" className="block text-sm font-medium text-gray-700">Author Name</label>
            <input
              type="text"
              name="auther_name"
              value={formData.auther_name}
              onChange={handleInputChange}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>

          {/* Image Link Input */}
          <div className="mb-4">
            <label htmlFor="image_link" className="block text-sm font-medium text-gray-700">Image Link</label>
            <input
              type="text"
              name="image_link"
              value={formData.image_link}
              onChange={handleInputChange}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>

          {/* Color Code Input */}
          <div className="mb-4">
            <label htmlFor="color_code" className="block text-sm font-medium text-gray-700">Color Code</label>
            <input
              type="text"
              name="color_code"
              value={formData.color_code}
              onChange={handleInputChange}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>

          {/* Buttons */}
          <div className="flex justify-between mt-6">
            <button
              type="button"
              onClick={onClose}
              className="px-6 py-2 text-sm font-medium text-gray-700 bg-gray-200 rounded-lg hover:bg-gray-300 focus:outline-none"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-6 py-2 text-sm font-medium text-white bg-blue-600 rounded-lg hover:bg-blue-700 focus:outline-none"
            >
              Add Blog
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default AddBlogModal;
