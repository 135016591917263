import React, {useState} from 'react';
import logo from '../../../assets/logo_ritesh.png'
import Avtarlogo from '../../../assets/avtrat1.png'
import { login } from '../../../services/api';
import { useNavigate } from 'react-router-dom';
import { loginUser } from '../../../store/authSlice';
import { useDispatch } from 'react-redux';

const Login = () => {
    const [formData, setFormData] = useState({ email: '', password: '' });
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (loading) return;  // Prevent duplicate submissions if already loading
        setLoading(true);
        setError('');
    
        try {
            const response = await login(formData);
            console.log("Login successful:", response.data);
    
            // Dispatch the loginUser action to update the Redux store
            dispatch(loginUser(response.data.currentUser));  // Pass the currentUser data
    
            // Navigate to dashboard after login
            navigate('/dashboard');
        } catch (err) {
            setError(err.response?.data?.message || "Login failed. Please try again.");
        } finally {
            setLoading(false);
        }
    };
    

    return (
        <div className='bg-gray-900 flex items-center justify-center h-screen px-4 bg-gradient-to-r from-blue-900 to-black  mt-4' >

            <div className="max-w-screen-xl m-0 sm:m-10  sm:rounded-lg flex justify-center flex-1 text-white">
                <div className="lg:w-1/2 xl:w-5/12 p-6 sm:p-12">
                    <div className='flex items-center justify-center'>
                        <img src={logo}
                            className="w-32 h-32 mx-auto" />
                    </div>
                    <div className="mt-12 flex flex-col items-center">
                        <h1 className="flex items-center justify-centertext-2xl xl:text-3xl font-extrabold">
                            Login
                        </h1>
                        <div className="w-full flex-1 mt-8">

                        <form onSubmit={handleSubmit} className="mx-auto max-w-xs">
                                <input
                                    className="w-full text-black px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white"
                                    type="email"
                                    name="email"
                                    placeholder="Email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    required
                                />
                                <input
                                    className="w-full  text-black px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5"
                                    type="password"
                                    name="password"
                                    placeholder="Password"
                                    value={formData.password}
                                    onChange={handleChange}
                                    required
                                />
                                {error && <p className="text-red-500 mt-2">{error}</p>}
                                <button
                                    type="submit"
                                    className="flex items-center justify-center mt-5 tracking-wide font-semibold bg-indigo-500 text-gray-100 w-full py-4 rounded-lg hover:bg-indigo-700 transition-all duration-300 ease-in-out focus:shadow-outline focus:outline-none"
                                    disabled={loading}
                                >
                                    {loading ? "Logging in..." : "Login"}
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="flex-1 bg-indigo-100 text-center hidden lg:flex rounded">
                    <img src={Avtarlogo} />                        
                </div>
            </div>
        </div>
        
    )
}

export default Login;