import React, { useState, useEffect } from "react";
import { PersonalInfoapi, PersonalInfoget, addEducation, deleteeducation } from "../../../../services/api";
import { useSelector } from "react-redux";
import { IoTrashBin } from "react-icons/io5";
import { MdAddCircle } from "react-icons/md";
import { FaEdit } from "react-icons/fa";
import { TiCancel } from "react-icons/ti";



const Education = () => {
    const userId = useSelector((state) => state.auth.user?.currentUser?._id) || null;
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [educations, setEducations] = useState([]);
    const [showEditEduModal, setShowEditEduModal] = useState(false);
    const [selectedEdu, setSelectedEdu] = useState(null);
    const [showAddModal, setShowAddModal] = useState(false);
    
    const [formData, setFormData] = useState({
        course_name: "",
        specification: "",
        institute: "",
        percentage: "",
        edu_startDate: "",
        edu_endDate: "",
        institute_address: "",
    });

    useEffect(() => {
        if (!userId) return;
        const fetchEduData = async () => {
            setLoading(true);
            try {
                const response = await PersonalInfoget(userId);
                console.log("ediucation response: ", response.data.user.education);
                setEducations(response.data.user.education);
            } catch (err) {
                setError("Failed to fetch user data.");
            } finally {
                setLoading(false);
            }
        };
        fetchEduData();
    }, [userId]);

    useEffect(() => {
        if (selectedEdu) {
            setFormData(selectedEdu);
        }
    }, [selectedEdu]);

    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleUpdateEducation = async () => {
        try {
            const updatedData = {
                education: educations.map((edu) =>
                    edu._id === selectedEdu._id ? { ...edu, ...formData } : edu
                ),
            };

            await PersonalInfoapi(userId, updatedData);
            setEducations(updatedData.education);
            setShowEditEduModal(false);
            setSelectedEdu(null);
        } catch (error) {
            console.error("Error updating education:", error);
        }
    };

    const handleAddEducation = async () => {
        if (!userId) {
            console.error("User ID not found");
            return;
        }
        try {
            setLoading(true);
            console.log("formData: ", formData);
            const response = await addEducation(userId, formData);
            setEducations(response.data.education);
            setShowAddModal(false);
            setFormData({
                course_name: "",
                specification: "",
                institute: "",
                percentage: "",
                edu_startDate: "",
                edu_endDate: "",
                institute_address: "",
            });
        } catch (error) {
            console.error("Error adding education:", error);
        } finally {
            setLoading(false);
        }
    };

    const handledeleteinforow = async (id) => {
        if (!userId) {
            console.error("User ID not found");
            return;
        }
        try {
            await deleteeducation(userId, id);
            setEducations((prevEducations) => prevEducations.filter((edu) => edu._id !== id));
            setShowEditEduModal(false);
            
        } catch (error) {
            console.error("Error deleting education:", error);
        }
    };
    
    

    return (
        <div>
            <div className="p-4 flex items-center justify-between text-white border-2 border-gray-200 border-dashed rounded-lg dark:border-gray-700">
                <h1 className="text-2xl xl:text-3xl font-extrabold">Education</h1>
                <button
                    type="button"
                    className="flex items-center justify-center gap-2 text-white bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5"
                    onClick={() => setShowAddModal(true)}
                >
                    <svg className="w-6 h-6 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 12h14m-7 7V5" />
                    </svg>
                    Add Education
                </button>
            </div>

            <div className="p-4 mt-3 border-2 border-gray-200 border-dashed rounded-lg dark:border-gray-700">
                <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                    <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                            <tr>
                                <th className="px-6 py-3">Course Name</th>
                                <th className="px-6 py-3">Specification</th>
                                <th className="px-6 py-3">Institute</th>
                                <th className="px-6 py-3">Percentage</th>
                                <th className="px-6 py-3">Start Date</th>
                                <th className="px-6 py-3">End Date</th>
                                <th className="px-6 py-3">Institute Address</th>
                            </tr>
                        </thead>
                        <tbody>
                            {educations.map((education) => (
                                <tr key={education._id} onClick={() => { setSelectedEdu(education); setShowEditEduModal(true);}} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                    <td className="px-6 py-3">{education.course_name}</td>
                                    <td className="px-6 py-3">{education.specification}</td>
                                    <td className="px-6 py-3">{education.institute}</td>
                                    <td className="px-6 py-3">{education.percentage}</td>
                                    <td className="px-6 py-3">{education.edu_startDate}</td>
                                    <td className="px-6 py-3">{education.edu_endDate}</td>
                                    <td className="px-6 py-3">{education.institute_address}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>

            {showEditEduModal && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                    <div className="bg-white p-5 rounded-lg w-1/3">
                        <h2 className="text-xl font-bold mb-4">Edit Education</h2>
                        <input type="text" name="course_name" value={formData.course_name} placeholder="Course Name" className="block w-full p-2 border rounded mb-2" onChange={handleInputChange} />
                        <input type="text" name="specification" value={formData.specification} placeholder="Specification" className="block w-full p-2 border rounded mb-2" onChange={handleInputChange} />
                        <input type="text" name="institute" value={formData.institute} placeholder="Institute" className="block w-full p-2 border rounded mb-2" onChange={handleInputChange} />
                        <input type="text" name="percentage" value={formData.percentage} placeholder="Percentage" className="block w-full p-2 border rounded mb-2" onChange={handleInputChange} />
                        <input type="date" name="edu_startDate" value={formData.edu_startDate} className="block w-full p-2 border rounded mb-2" onChange={handleInputChange} />
                        <input type="date" name="edu_endDate" value={formData.edu_endDate} className="block w-full p-2 border rounded mb-2" onChange={handleInputChange} />
                        <input type="text" name="institute_address" value={formData.institute_address} placeholder="Institute Address" className="block w-full p-2 border rounded mb-2" onChange={handleInputChange} />

                        <div className="flex justify-between gap-2 mt-4">
                            <button className="bg-gray-500 text-white px-4 py-2 rounded flex gap-2 items-center justify-center" onClick={() => { setShowEditEduModal(false); setSelectedEdu(null); }}><TiCancel /> Cancel</button>
                            <button className="bg-green-500 text-white px-4 py-2 rounded flex gap-2 items-center justify-center" onClick={handleUpdateEducation}><FaEdit/> Update</button>
                            <button className="bg-red-500 flex gap-2 items-center justify-center text-white px-4 py-2 rounded" onClick={() => { handledeleteinforow(formData._id); }}> <IoTrashBin/> Delete</button>
                        </div>
                    </div>
                </div>
            )}


            {(showAddModal) && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                    <div className="bg-white p-5 rounded-lg w-1/3">
                        <h2 className="text-xl font-bold mb-4">{showEditEduModal ? "Edit Education" : "Add Education"}</h2>
                        <input type="text" name="course_name" placeholder="Course Name" className="block w-full p-2 border rounded mb-2" onChange={handleInputChange} />
                        <input type="text" name="specification" placeholder="Specification" className="block w-full p-2 border rounded mb-2" onChange={handleInputChange} />
                        <input type="text" name="institute" placeholder="Institute" className="block w-full p-2 border rounded mb-2" onChange={handleInputChange} />
                        <input type="text" name="percentage" placeholder="Percentage" className="block w-full p-2 border rounded mb-2" onChange={handleInputChange} />
                        <input type="date" name="edu_startDate" className="block w-full p-2 border rounded mb-2" onChange={handleInputChange} />
                        <input type="date" name="edu_endDate" className="block w-full p-2 border rounded mb-2" onChange={handleInputChange} />
                        <input type="text" name="institute_address" placeholder="Institute Address" className="block w-full p-2 border rounded mb-2" onChange={handleInputChange} />
                        <div className="flex justify-between gap-2 mt-4">
                            <button className="bg-red-500 text-white px-4 py-2 rounded flex gap-2 items-center justify-center" onClick={() => { setShowEditEduModal(false); setShowAddModal(false); }}> <TiCancel />Close</button>
                            <button className="bg-green-500 text-white px-4 py-2 rounded flex gap-2 items-center justify-center" onClick={handleAddEducation}> <MdAddCircle/> Add Education</button>
                        </div>
                    </div>
                </div>
            )}

        </div>
    );
};

export default Education;
